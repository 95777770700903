import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => (
  <Layout textblock={true}>
    <SEO title="About" />
    <section className="item">
      <p>
        Going back as far as 2008, Nutriot started out as a simple music blog. A
        space where personal findings and favourites were shared with the world. It
        was the still the hey-day of the blog and during its most active time, an
        average of 500 readers frequented the site every day.
      </p>

      <p>
        After relocating from São Paulo to Vienna, the idea to release music was
        born. In 2012 the new label Nutriot Recordings was officially registered,
        but it took another three long years until the first record came out vinyl.
      </p>

      <p>
        Since then, we have released music from the far corners of the earth, from
        New Zealand to Brazil, the United Kingdom and our native Austria. We continue
        to seek out music that's challenging and obscure, as long as we enjoy 
        listening it ourselves &mdash; no matter its genre or age.
      </p>
    </section>
  </Layout>
)

export default AboutPage
